<template>
  <div class="row">
    <div v-show="impr.ibmStepCd !== 'IS00000001' && impr.suppleCheckFlag !== 'Y'" class="col-12">
      <c-step
        stepperGrpCd="IBM_STEP_CD"
        :currentStepCd="impr.ibmStepCd"
        :disabled="true"
        v-model="impr.ibmStepCd">
      </c-step>
    </div>
    <div v-show="suppleFlag" class="col-12">
      <c-step
        stepperGrpCd="IBM_SUPPLE_STEP_CD"
        :currentStepCd="impr.ibmSuppleStepCd"
        :disabled="true"
        v-model="impr.ibmSuppleStepCd">
      </c-step>
    </div>
    <div v-if="overFlag && this.impr.approvalStatusCd !== 'ASC9999999'" class="col-12">
      <q-banner dense inline-actions class="text-white bg-red">
        <template v-slot:avatar>
          <q-icon name="report_problem" color="grey-3" />
        </template>
        <b class="text-h5">지연</b>
      </q-banner>
    </div>
    <div v-show="impr.suppleCheckFlag === 'Y'" 
      :class="impr.saveSuppleFlag === 'Y' ? 'col-xs-12 col-sm-12 col-md-12 col-lg-6' : 'col-12'">
      <div class="row">
        <div class="col-12">
          <q-form ref="editSuppleForm"> 
            <c-card title="보완 요청 상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn 
                    v-show="suppleRequestEditable" 
                    :isSubmit="isSaveSupple"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="저장" 
                    icon="save" 
                    @beforeAction="saveSupple"
                    @btnCallback="saveSuppleCallback" />
                  <c-btn 
                    v-show="suppleRequestEditable" 
                    :isSubmit="isSaveSuppleRequest"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="보완요청" 
                    icon="send" color="red"
                    @beforeAction="saveSuppleRequest"
                    @btnCallback="saveSuppleRequestCallback" />
                </q-btn-group>
              </template>
              <template slot="card-description">
                <font v-if="!impr.saveSuppleFlag" class="text-negative" style="font-size:0.8em;font-weight:300;">
                  (※ 적합 / 보완 처리가 되지 않았습니다.)
                </font>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                  <c-select
                    :editable="suppleEditable"
                    :comboItems="suppleFlagItems"
                    itemText="codeName"
                    itemValue="code"
                    label="적합/보완 여부"
                    name="suppleFlag"
                    v-model="impr.suppleFlag"
                    @datachange="supplechange">
                  </c-select>
                </div>
                <div v-if="impr.saveSuppleFlag === 'Y'" class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                  <c-datepicker
                    :editable="suppleRequestEditable"
                    :required="true"
                    label="보완완료 요청일"
                    name="suppleScheduleDate"
                    v-model="impr.suppleScheduleDate"
                  />
                </div>
                <div v-if="impr.saveSuppleFlag === 'Y'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="suppleRequestEditable"
                    :required="true"
                    label="보완요청내용"
                    name="suppleRequest"
                    v-model="impr.suppleRequest">
                  </c-textarea>
                </div>
                <div v-show="impr.saveSuppleFlag === 'Y'" class="col-12">
                  <c-upload 
                    ref="suppleAttach"
                    :attachInfo="attachSupple"
                    :editable="suppleAttachEditable"
                    label="보완 관련 첨부파일">
                  </c-upload>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div>
    <div v-show="impr.suppleCheckFlag === 'Y' && impr.saveSuppleFlag === 'Y'" class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
      <div class="row">
        <div class="col-12">
          <q-form ref="editSuppleActionReviewForm"> 
            <c-card title="보완 조치&검토 상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn 
                    v-show="suppleReviewEditable" 
                    :isSubmit="isReturnSupple"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="반려" 
                    icon="front_hand" color="red-3"
                    @beforeAction="returnSupple"
                    @btnCallback="returnSuppleCallback" />
                  <c-btn 
                    v-show="suppleActionReviewBtnEditable" 
                    :isSubmit="isSaveSuppleActionReview"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="저장" 
                    icon="save" 
                    @beforeAction="saveSuppleActionReview"
                    @btnCallback="saveSuppleActionReviewCallback" />
                  <c-btn 
                    v-show="suppleReviewEditable" 
                    :isSubmit="isSaveSuppleComplete"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="보완완료" 
                    icon="directions_run" color="red"
                    @beforeAction="saveSuppleComplete"
                    @btnCallback="saveSuppleCompleteCallback" />
                  <c-btn 
                    v-show="suppleActionEditable" 
                    :isSubmit="isSaveSuppleReview"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="검토요청" 
                    icon="send" color="red"
                    @beforeAction="saveSuppleReview"
                    @btnCallback="saveSuppleReviewCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                  <c-datepicker
                    :editable="suppleActionEditable"
                    :required="true"
                    label="보완완료일"
                    name="suppleCompleteDate"
                    v-model="impr.suppleCompleteDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="suppleActionEditable"
                    :required="true"
                    :rows="4"
                    label="보완처리내용"
                    name="suppleActionContents"
                    v-model="impr.suppleActionContents">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="suppleReviewEditable"
                    :rows="4"
                    label="보완검토내용"
                    name="suppleReviewContents"
                    v-model="impr.suppleReviewContents">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
      <div class="row">
        <div class="col-12">
          <q-form ref="editForm1">
            <c-card title="요청&접수 상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <component
                    :is='relationTableInfo.component' 
                    :key='relationTableInfo.key' 
                    :taskParam="relationTableInfo.taskParam"
                    :disabled="!Boolean(popupParam.sopImprovementId)"
                  />
                  <c-btn 
                    v-show="requestCancelBtnEditable" 
                    :isSubmit="isReject"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="요청취소" 
                    icon="delete_forever"
                    @beforeAction="rejectImpr"
                    @btnCallback="rejectCallback" />
                  <c-btn 
                    v-show="requestAreaEditable&&popupParam.sopImprovementId" 
                    :isSubmit="isDelete"
                    :url="deleteUrl"
                    :param="impr"
                    mappingType="DELETE"
                    label="삭제" 
                    icon="remove"
                    @beforeAction="removeImpr"
                    @btnCallback="removeCallback" />
                  <c-btn 
                    v-show="saveBtnEditable1" 
                    :isSubmit="isSaveRequestReceipt"
                    :url="saveUrl"
                    :param="impr"
                    :mappingType="mappingType"
                    label="저장" 
                    icon="save" 
                    @beforeAction="saveRequestReceiptImpr"
                    @btnCallback="saveRequestReceiptCallback" />
                  <c-btn 
                    v-show="requestBtnEditable" 
                    :isSubmit="isRequest"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="조치부서에 요청" 
                    icon="send" color="red"
                    @beforeAction="requestImpr"
                    @btnCallback="requestCallback" />
                  <c-btn 
                    v-show="receiptBtnEditable" 
                    :isSubmit="isReceipt"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="접수" 
                    icon="directions_run" color="red"
                    @beforeAction="receiptImpr"
                    @btnCallback="receiptCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                  <c-select
                    :disabled="true"
                    :editable="editable"
                    :comboItems="taskComboItems"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    name="ibmTaskTypeCd"
                    label="업무"
                    v-model="impr.ibmTaskTypeCd"
                  ></c-select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                  <c-text
                    :disabled="true"
                    :editable="editable"
                    name="sopImprovementId"
                    label="개선번호"
                    v-model="impr.sopImprovementId"
                  ></c-text>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-text
                    :editable="editable"
                    :disabled="true"
                    label="요청부서/이름/요청일"
                    name="request"
                    :value="request">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-text
                    :editable="requestAreaEditable"
                    :required="true"
                    label="제목"
                    name="ibmTitle"
                    v-model="impr.ibmTitle">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="requestAreaEditable"
                    :required="true"
                    label="요청내용"
                    name="improveRequestContents"
                    v-model="impr.improveRequestContents">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <!-- 관련공사 -->
                  <c-construnction
                    :editable="requestAreaEditable"
                    name="sopConstructionId"
                    v-model="impr.sopConstructionId">
                  </c-construnction>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-8">
                      <c-plant
                        :editable="requestAreaEditable"
                        :required="true"
                        type="edit" 
                        name="plantCd" 
                        v-model="impr.plantCd" />
                    </div>
                    <div class="col-4">
                      <c-checkbox
                        :editable="false"
                        :isFlag="true"
                        trueLabel="업체"
                        falseLabel="부서"
                        trueValue="V"
                        falseValue="D"
                        label=""
                        name="actionFlag"
                        v-model="impr.actionFlag"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <template v-if="vendorCheck">
                    <c-vendor
                      v-show="impr.actionFlag === 'V'"
                      :editable="false"
                      type="edit" 
                      label="조치업체" 
                      name="actionVendorCd" 
                      v-model="impr.actionVendorCd" />
                  </template>
                  <template v-else>
                    <c-vendor-other
                      v-show="impr.actionFlag === 'V'"
                      :editable="requestAreaEditable"
                      :required="impr.actionFlag === 'V'"
                      :selfFlag="false"
                      type="edit" 
                      label="조치업체" 
                      name="actionVendorCd" 
                      v-model="impr.actionVendorCd" />
                  </template>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-datepicker
                    :editable="requestAreaEditable"
                    :required="true"
                    label="조치완료 요청일"
                    name="actionCompleteRequestDate"
                    v-model="impr.actionCompleteRequestDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-field 
                    :editable="requestAreaEditable||actionReceiptAreaEditable"
                    type="dept_user" 
                    :data="impr"
                    deptValue="actionCompleteCheckDeptCd"
                    label="조치완료 확인자" 
                    name="actionCompleteCheckUserId" 
                    v-model="impr.actionCompleteCheckUserId" />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="actionReceiptAreaEditable"
                    label="요청취소 사유"
                    name="requestRejectReason"
                    v-model="impr.requestRejectReason">
                  </c-textarea>
                </div>
                <div class="col-12">
                  <c-upload 
                    :attachInfo="attachBeforeInfo"
                    :editable="requestAreaEditable||actionReceiptAreaEditable||actionAreaEditable"
                    label="개선 전 사진">
                  </c-upload>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
      <div class="row">
        <div class="col-12" v-show="overFlag">
          <q-form ref="overForm">
            <c-card title="지연상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn 
                    v-show="overEditable" 
                    :isSubmit="isOver"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="저장" 
                    icon="save"
                    @beforeAction="saveOverImpr"
                    @btnCallback="saveOverCallback" />
                  <c-btn 
                    v-show="overEditable" 
                    :isSubmit="isOverComplete"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="목표일 설정 완료" 
                    icon="check"
                    @beforeAction="approvalOverImpr"
                    @btnCallback="approvalOverImrpCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-datepicker
                    :editable="overEditable"
                    :required="true"
                    :start="$comm.getToday()"
                    label="목표일"
                    name="targetDate"
                    v-model="impr.targetDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="overEditable"
                    :required="true"
                    label="지연사유"
                    name="delayReason"
                    v-model="impr.delayReason">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
        <div class="col-12">
          <q-form ref="editForm2">
            <c-card title="조치상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn 
                    v-show="saveBtnEditable2" 
                    :isSubmit="isSaveAction"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="저장" 
                    icon="save"
                    @beforeAction="saveActionImpr"
                    @btnCallback="saveActionCallback" />
                  <c-btn 
                    v-show="actionCompleteBtnEditable" 
                    :isSubmit="isActionComplete"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="조치완료" 
                    icon="directions_run" color="red"
                    @beforeAction="actionCompleteImpr"
                    @btnCallback="actionCompleteCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-datepicker
                    :editable="actionAreaEditable"
                    :required="true"
                    label="조치완료일"
                    name="actionCompleteDate"
                    v-model="impr.actionCompleteDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-datepicker
                    :editable="actionAreaEditable"
                    :required="true"
                    label="검토완료 요청일"
                    name="reviewCompleteRequestDate"
                    v-model="impr.reviewCompleteRequestDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="actionAreaEditable"
                    :required="true"
                    label="조치내용"
                    name="actionContents"
                    v-model="impr.actionContents">
                  </c-textarea>
                </div>
                <div class="col-12">
                  <c-upload 
                    :attachInfo="attachAfterInfo"
                    :editable="actionAreaEditable"
                    label="개선 후 사진">
                  </c-upload>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
        <div class="col-12">
          <q-form ref="editForm3">
            <c-card title="검토상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn 
                    v-show="rejectBtnEditable" 
                    :isSubmit="isReturn"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="반려" 
                    icon="front_hand" color="red-3"
                    @beforeAction="returnImpr"
                    @btnCallback="returnCallback" />
                  <c-btn 
                    v-show="saveBtnEditable3" 
                    :isSubmit="isSaveCheck"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="저장" 
                    icon="save"
                    @beforeAction="saveCheckImpr"
                    @btnCallback="saveCheckCallback" />
                  <c-btn 
                    v-show="completeBtnEditable" 
                    :isSubmit="isComplete"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="개선완료" 
                    icon="check_circle_outline" color="red"
                    @beforeAction="completeImpr"
                    @btnCallback="completeCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-12">
                  <c-textarea
                    :editable="requestCheckAreaEditable"
                    :required="true"
                    label="검토내용"
                    name="reviewContents"
                    v-model="impr.reviewContents">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'impr-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          isSearch: false,
          sopImprovementId: '',
          ibmTaskTypeCd: '',
          ibmTaskUnderTypeCd: '',  // 업무하위분류코드
          data: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    returnData: {
      type: Object,
      default: function() {
        return {
          col1: null,
        }
      },
    }
  },
  data() {
    return {
      impr: {
        sopImprovementId: '',  // 개선 일련번호
        plantCd: '',  // 사업장 코드
        ibmTaskTypeCd: '',  // 업무분류코드
        ibmTaskUnderTypeCd: '',  // 업무하위분류코드
        ibmTitle: '',  // 제목
        request: '',
        improveRequestDeptCd: '',  // 개선요청부서
        improveRequestDeptName: '', // 개선요청부서명
        improveRequestUserId: '',  // 개선요청자
        improveRequestUserName: '',  // 개선요청자명
        improveRequestContents: '',  // 개선요청내용
        ibmStepCd: '',  // 개선진행단계
        relationTableKey: '',  // 관련테이블 키
        relationVendor: '',  // 관련업체_기술
        actionScheduleDate: '',  // 조치예정일
        actionCompleteRequestDate: '',  // 조치완료 요청일
        reviewCompleteRequestDate: '',  // 검토완료 요청일
        actionCompleteDate: '',  // 조치완료일
        actionDeptCd: '',  // 조치부서_조치부서는 변경가능
        actionUserIds: [],  // 조치자s
        actionContents: '',  // 조치내용_조치부서에서
        reviewContents: '',  // 요청부서 조치결과 검토_요청부서에서
        actionCompleteCheckUserId: '',  // 조치완료 확인자_부서장 또는 공장장
        actionCompleteCheckFlag: '',  // 조치완료 확인자 확인 여부
        requestRejectReason: '', // 요청취소사유

        targetDate: '',  // 목표일 - 지연
        delayReason: '',  // 지연사유
        delayVendorFlag: '',
        completeDateOver: 0,
        
        saveSuppleFlag: '', // 적합/보완 여부 저장된 정보
        suppleFlag: '',  // 적합/보완 여부
        ibmSuppleStepCd: '',  // 개선보완진행단계
        suppleRequest: '',  // 보완할 사항
        suppleScheduleDate: '',  // 보완완료 요청일
        suppleActionContents: '',  // 보완처리 내용
        suppleCompleteDate: '',  // 보완완료일
        suppleReviewContents: '',  // 보완 검토 내용
        suppleCheckFlag: 'N',

        sopConstructionId: '',

        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        regUserId: '',  // 등록자 ID
        regDt: '',
        chgUserId: '',  // 수정자 ID
        actionUsers: [],
        stepFlag: 0,
      },
      taskComboItems: [],
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'IBM_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        taskClassCd: 'IBM_AFTER',
        taskKey: '',
      },
      attachSupple: {
        isSubmit: '',
        taskClassCd: 'IBM_SUPPLE',
        taskKey: '',
      },
      mappingType: 'PUT',
      editable: true,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSaveRequestReceipt: false, // 요청&접수 저장 flag
      isRequest: false, // 요청 flag
      isReceipt: false, // 접수 flag
      isReject: false, // 요청취소 flag
      isDelete: false, // 삭제 flag
      isActionComplete: false, // 조치완료 flag
      isSaveAction: false, // 조치 저장 flag
      isSaveCheck: false, // 검토 저장 flag
      isComplete: false, // 개선완료 flag
      isReturn: false, // 조치반려 flag
      isOver: false, // 지연 저장 flag
      isOverComplete: false, // 지연 목표일 설정 완료 flag
      isApproval: false, // 지연 결재 flag
      isSaveSuppleRequest: false, // 보완 요청 flag
      isSaveSupple: false, // 보완 정보 저장 flag
      isSaveSuppleActionReview: false, // 보완 정보 저장 flag
      isSaveSuppleComplete: false, // 보완완료 flag
      isSaveSuppleReview: false, // 보완 검토요청 flag
      isReturnSupple: false, // 보완 반려 flag
    };
  },
  computed: {
    request() {
      if (this.impr.improveRequestDeptName !== this.impr.improveRequestUserName) {
        return this.impr.improveRequestDeptName + ' / ' + this.impr.improveRequestUserName + ' / '  + this.impr.regDt
      } else {
        return this.impr.improveRequestUserName + ' / '  + this.impr.regDt
      }
    },
    disabled() {
      return this.popupParam.isSearch || this.impr.ibmStepCd === 'IS00000020'
        // 결재중인 경우 비활성화
        || this.impr.approvalStatusCd === 'ASC0000001';
    },
    imprEditable() {
      return this.editable && !this.disabled && (this.impr.approvalStatusCd === 'ASC9999999' ? true : !this.overFlag)
    },
    requestAreaEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000001' // 요청중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    actionReceiptAreaEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && this.impr.actionVendorCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    actionAreaEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && this.impr.actionVendorCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    requestCheckAreaEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    overEditable() {
      return this.editable
        && this.impr.approvalStatusCd !== 'ASC9999999'
        && !this.disabled
        && this.impr.actionVendorCd === this.$store.getters.user.deptCd
    },
    overApprEditable() {
      return this.editable
        && this.impr.approvalStatusCd !== 'ASC9999999'
        && this.impr.actionVendorCd === this.$store.getters.user.deptCd
    },
    suppleAttachEditable() {
      return this.editable 
        && this.impr.ibmStepCd === 'IS00000020' // 개선완료
        && this.impr.suppleCheckFlag === 'Y' // 보완 작성 여부
        && this.impr.ibmSuppleStepCd !== 'ISS0000015' // 보완완료 상태인 경우
        && this.impr.saveSuppleFlag === 'Y'
    },
    suppleEditable() {
      return this.editable 
        && this.impr.ibmStepCd === 'IS00000020' // 개선완료
        && this.impr.suppleCheckFlag === 'Y' // 보완 작성 여부
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
        && this.impr.ibmSuppleStepCd !== 'ISS0000015' // 보완 진행단계가 없거나 요청상태인 경우
    },
    suppleRequestEditable() {
      return this.editable 
        && this.impr.ibmStepCd === 'IS00000020' // 개선완료
        && this.impr.suppleCheckFlag === 'Y' // 보완 작성 여부
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
        && this.impr.ibmSuppleStepCd === 'ISS0000001' // 보완 요청상태인 경우
    },
    suppleActionEditable() {
      return this.editable 
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
        && this.impr.ibmSuppleStepCd === 'ISS0000005' // 보완 조치상태인 경우
    },
    suppleReviewEditable() {
      return this.editable 
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
        && this.impr.ibmSuppleStepCd === 'ISS0000010' // 보완 검토상태인 경우
    },
    suppleActionReviewBtnEditable() {
      return this.editable 
        && (this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd || this.impr.actionDeptCd === this.$store.getters.user.deptCd) // 로그인자의 부서가 요청부서이거나 조치부서인 경우
        && (this.impr.ibmSuppleStepCd === 'ISS0000005' || this.impr.ibmSuppleStepCd === 'ISS0000010') // 보완 조치 또는 검토 상태인 경우
    },
    requestBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000001' // 요청중 상태
        && this.popupParam.sopImprovementId // key가 있는 경우, 저장이 한번 된 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    receiptBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && this.impr.actionVendorCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    requestCancelBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && this.impr.actionVendorCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    actionCompleteBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && this.impr.actionVendorCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    rejectBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    completeBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    tempSaveBtnEditable() {
      return this.imprEditable 
        && this.popupParam.data && !this.popupParam.sopImprovementId
    },
    saveBtnEditable1() {
      return this.imprEditable 
        && ((this.impr.ibmStepCd === 'IS00000001' // 요청중 and 로그인자의 부서가 요청부서
          && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd)
        )
        // || (this.impr.ibmStepCd === 'IS00000005'
        //  && this.impr.actionDeptCd === this.$store.getters.user.deptCd)) // 조치부서 접수중 and 조치부서인 경우
    },
    saveBtnEditable2() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && this.impr.actionVendorCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    saveBtnEditable3() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    suppleFlag() {
      return this.impr.saveSuppleFlag === 'Y' 
        && this.impr.ibmSuppleStepCd
    },
    overFlag() {
      /**
       * 지연된 데이터 체크
       * 접수중 단계부터 체크하며 지연된 데이터의 경우 지연 프로세스 동작
       * 지연 정보를 제외한 나머지 정보는 비활성화 처리
       */
      return this.impr.completeDateOver > 0
        && this.impr.ibmStepCd !== 'IS00000001'
        // && this.impr.targetDate
    },
    suppleFlagItems() {
      if (this.impr.saveSuppleFlag) {
        return [
          { code: 'Y', codeName: '보완' },
          { code: 'N', codeName: '적합' },
        ];
      } else {
        return [
          { code: '', codeName: '선택' },
          { code: 'Y', codeName: '보완' },
          { code: 'N', codeName: '적합' },
        ];
      }
    },
    vendorCheck() {
      return this.$store.getters.user.vendorClassCd !== 'VCC0000003';
    },
    relationTableInfo() {
      return {
        component: () => import(`${'@/pages/sop/ibm/imprRelationTag.vue'}`),
        key: uid(),
        taskParam: this.impr
      }
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.ibm.improve.get.url;
      this.saveUrl = transactionConfig.sop.ibm.improve.update.url
      this.deleteUrl = transactionConfig.sop.ibm.improve.delete.url
      this.approvalUrl = transactionConfig.sop.ibm.improve.approval.url
      // code setting
      this.setTaskItems();
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.sopImprovementId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopImprovementId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.impr, _result.data);

          this.impr.saveSuppleFlag = this.$_.clone(this.impr.suppleFlag)

          this.attachBeforeInfo.taskKey = this.$_.clone(this.impr.sopImprovementId)
          this.attachAfterInfo.taskKey = this.$_.clone(this.impr.sopImprovementId)
          this.attachSupple.taskKey = this.$_.clone(this.impr.sopImprovementId)

          if (this.impr.actionUsers && this.impr.actionUsers.length > 0) {
            this.$set(this.impr, 'actionUserIds', this.$_.map(this.impr.actionUsers, 'actionUserId'))
          } else {
            this.$set(this.impr, 'actionUserIds', [])
          }

          this.returnData.col1 = this.impr;
        },);
      } else {
        /**
         * 데이터가 들어오지 않은 경우 두 가지의 case로 처리
         * 
         * case 1. popupParam.data가 있는 경우
         *  - 해당 데이터에 새로 들어온 데이터가 있음으로 해당 데이터를 impr에 extend 처리
         * case 2. popupParam.data가 없는 경우 경우
         *  - 해당 팝업으로 개선요청을 등록하는 경우로 업무 및 요청정보를 setting한다.
         */
        if (this.popupParam.data) {
          // case 1.
          this.$_.extend(this.impr, this.popupParam.data)
        } else {
          // case 2.
          this.impr.ibmClassCd = 'IC00000001';
          this.impr.ibmStepCd = 'IS00000001'
          this.impr.ibmTaskTypeCd = 'ITT0000145';
          this.impr.ibmTaskUnderTypeCd = 'ITTU000145';
          this.impr.actionFlag = 'V';
          this.impr.improveRequestDeptCd = this.$store.getters.user.deptCd;
          this.impr.improveRequestDeptName = this.$store.getters.user.deptName;
          this.impr.improveRequestUserId = this.$store.getters.user.userId;
          this.impr.improveRequestUserName = this.$store.getters.user.userName;
          this.impr.regDt = this.$comm.getToday();
        }
      }
    },
    setTaskItems() {
      this.$comm.getComboItems('IBM_TASK_TYPE_CD').then(_result => {
        this.taskComboItems = _result
      });
    },
    supplechange(data) {
      if (!data.value) return;
      let message = '보완이 필요한 개선요청건으로 지정하시겠습니까?'
      if (data.value === 'N') {
        if (this.impr.ibmSuppleStepCd) {
          message = '보완을 위해 작성한 내용이 삭제됩니다.\n\r'
        }
        message += '적합으로 지정하시겠습니까?'
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: message,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.impr.chgUserId = this.$store.getters.user.userId;
          if (data.value === 'Y') {
            this.$set(this.impr, 'ibmSuppleStepCd', 'ISS0000001');
          } else {
            this.$set(this.impr, 'ibmSuppleStepCd', '');
            this.$set(this.impr, 'suppleRequest', '');
            this.$set(this.impr, 'suppleScheduleDate', '');
            this.$set(this.impr, 'suppleActionContents', '');
            this.$set(this.impr, 'suppleCompleteDate', '');
            this.$set(this.impr, 'suppleReviewContents', '');
            this.$refs['suppleAttach'].removeAllFilesMethod();
          }

          this.$http.url = this.saveUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.impr;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getDetail();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
          this.$set(this.impr, 'suppleFlag', this.impr.saveSuppleFlag)
        },
      });
    },
    /**
     * 요청&접수 상세 저장
     */
    saveRequestReceiptImpr() {
      if (this.popupParam.sopImprovementId) {
        this.mappingType = 'PUT';
      } else {
        this.mappingType = 'POST';
      }
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId
              
              this.isSaveRequestReceipt = !this.isSaveRequestReceipt
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveRequestReceiptCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.taskKey = result.data
      this.attachBeforeInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
      this.setTaskItems();
    },
    /**
     * 요청
     */
    requestImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '조치부서에 개선요청하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000005';
              this.impr.stepFlag = 1;
              
              this.isRequest = !this.isRequest
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    requestCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.isSubmit = uid()
      this.impr.stepFlag = 0;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 접수
     */
    receiptImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '요청건을 접수하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000010';
              
              this.isReceipt = !this.isReceipt
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    receiptCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.attachBeforeInfo.isSubmit = uid()
      this.getDetail();
    },
    /**
     * 요청취소
     */
    rejectImpr() {
      if (this.impr.requestRejectReason) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '요청취소하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.impr.chgUserId = this.$store.getters.user.userId;
            this.impr.ibmStepCd = 'IS00000001';
            
            this.isReject = !this.isReject
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '요청취소사유를 입력하세요.', // 요청취소사유를 입력하세요.
          type: 'warning', // success / info / warning / error
        });
      }
    },
    rejectCallback() {
      /**
       * 업체 포탈에서는 요청 취소할 경우 요청부서에 넘어감으로
       * 팝업을 닫는다.
       */
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('closePopup', this.returnData, 'reject');
    },
    /**
     * 삭제
     */
    removeImpr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.deleteUrl = this.$format(transactionConfig.sop.ibm.improve.delete.url, this.popupParam.sopImprovementId);

          this.isDelete = !this.isDelete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('closePopup', this.returnData, 'delete');
    },
    /**
     * 조치완료
     */
    actionCompleteImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        this.$refs['editForm2'].validate().then(__result => {
          if (_result && __result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '조치완료하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.impr.chgUserId = this.$store.getters.user.userId;
                this.impr.ibmStepCd = 'IS00000015';
                this.impr.stepFlag = 1;
                
                this.isActionComplete = !this.isActionComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      });
    },
    actionCompleteCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachAfterInfo.isSubmit = uid()
      this.impr.stepFlag = 0;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치 저장
     */
    saveActionImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        this.$refs['editForm2'].validate().then(__result => {
          if (_result && __result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.impr.chgUserId = this.$store.getters.user.userId;
                
                this.isSaveAction = !this.isSaveAction
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      });
    },
    saveActionCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치 저장
     */
    saveCheckImpr() {
      this.$refs['editForm3'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              
              this.isSaveCheck = !this.isSaveCheck
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCheckCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 개선완료
     */
    completeImpr() {
      this.$refs['editForm3'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '개선 완료처리하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000020';
              
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치반려
     */
    returnImpr() {
      this.$refs['editForm3'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '조치반려하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000010';
              
              this.isReturn = !this.isReturn
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    returnCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    saveOverImpr() {
      this.$refs['overForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              
              this.isOver = !this.isOver
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveOverCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    approvalOverImpr() {
      this.$refs['overForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '새로운 조치 목표일에 맞추시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.delayVendorFlag = 'Y'
              
              this.isOverComplete = !this.isOverComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    approvalOverImrpCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 보완요청
     */
    saveSuppleRequest() {
      this.$refs['editSuppleForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '조치부서에 보완조치요청 하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmSuppleStepCd = 'ISS0000005';
              
              this.isSaveSuppleRequest = !this.isSaveSuppleRequest
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSuppleRequestCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.attachSupple.isSubmit = uid()
      this.getDetail();
    },
    /**
     * 보완 정보 저장
     */
    saveSupple() {
      this.$refs['editSuppleForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              
              this.isSaveSupple = !this.isSaveSupple
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSuppleCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.attachSupple.isSubmit = uid()
      this.getDetail();
    },
    /**
     * 보완 정보 저장
     */
    saveSuppleActionReview() {
      this.$refs['editSuppleActionReviewForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              
              this.isSaveSuppleActionReview = !this.isSaveSuppleActionReview
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSuppleActionReviewCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.attachSupple.isSubmit = uid()
      this.getDetail();
    },
    /**
     * 보완완료
     */
    saveSuppleComplete() {
      this.$refs['editSuppleActionReviewForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '보완 완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmSuppleStepCd = 'ISS0000015';
              
              this.isSaveSuppleComplete = !this.isSaveSuppleComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSuppleCompleteCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.attachSupple.isSubmit = uid()
      this.getDetail();
    },
    /**
     * 보완 검토요청 저장
     */
    saveSuppleReview() {
      this.$refs['editSuppleActionReviewForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '검토 요청하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmSuppleStepCd = 'ISS0000010';
              
              this.isSaveSuppleReview = !this.isSaveSuppleReview
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSuppleReviewCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.attachSupple.isSubmit = uid()
      this.getDetail();
    },
    /**
     * 보완 반려
     */
    returnSupple() {
      if (!this.impr.suppleReviewContents) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '보완검토내용에 반려사유를 입력하시기 바랍니다.', // 보완검토내용에 반려사유를 입력하시기 바랍니다.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$refs['editSuppleActionReviewForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '반려하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmSuppleStepCd = 'ISS0000005';
              
              this.isReturnSupple = !this.isReturnSupple
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    returnSuppleCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.attachSupple.isSubmit = uid()
      this.getDetail();
    },
  }
};
</script>